export interface IBrand {
  name: string;
  value: string;
}

export type IHTMLInput = string | ReadonlyArray<string> | number | undefined;

export interface IDefaults {
  companyName: string;
  website: string;
  websiteShort: string;
  logo: string;
  logoWidth: number;
  logoHeight: number;
  tagline: string;
}

export interface IStyle {
  primaryColour: string;
  secondaryColour: string;
  lighterColour: string;
  font: string;
  fontFamily: string;
  fontFamilySecondary: string;
  defaultCellTopSpace: string;
}

//// Data
const brands: IBrand[] = [
  {
    name: "Bossa Games",
    value: "bossa-games",
  },
];

const defaults: IDefaults = {
  companyName: "Bossa Games",
  website: "https://www.bossastudios.com/",
  websiteShort: "bossastudios.com",
  // Here you can edit the logo URL if it ever changes
  // Make sure you update width and height (in px) appropriately so it's scaled to the correct size
  logo: "https://bossa-s3-signature.s3.eu-west-1.amazonaws.com/bossaGamesLogo.png",
  logoWidth: 128,
  logoHeight: 76,
  tagline: "Co-creating players most memorable stories",
};

const style: IStyle = {
  primaryColour: "#1D1D1B",
  secondaryColour: "#1D1D1B",
  lighterColour: "#717C98",
  font: "font-family:Helvetica,Arial,sans-serif;",
  fontFamily: "Arial,sans-serif",
  fontFamilySecondary: "Arial,sans-serif",
  defaultCellTopSpace: "6px",
};

export { brands, defaults, style };
