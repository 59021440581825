import { defaults, IBrand, style } from "../../data/data";

interface IEmailTableProps {
  brand: IBrand;
  name?: string;
  pronouns?: string;
  job?: string;
}
const EmailTable = ({ brand, name, pronouns, job }: IEmailTableProps) => {
  const { companyName, website, websiteShort, tagline, logo, logoWidth, logoHeight } = defaults;
  const title = `${companyName}${brand ? ` ${brand.name}` : ""} - ${tagline}`;
  const { secondaryColour, fontFamily, fontFamilySecondary, defaultCellTopSpace } = style;

  const renderLogo = () => (
    <a href={website} style={{ border: "none", textDecoration: "none", padding: 0, margin: 0 }}>
      <img
        title={title}
        alt={title}
        src={logo}
        width={logoWidth}
        height={logoHeight}
        style={{
          display: "block",
          fontFamily: fontFamilySecondary,
          color: secondaryColour,
          fontSize: "14px",
          width: logoWidth,
          height: logoHeight,
        }}
      />
    </a>
  );

  const renderName = () => {
    const border = {
      borderTop: `6px solid ${secondaryColour}`,
      borderBottom: `6px solid ${secondaryColour}`,
      borderLeft: `8px solid ${secondaryColour}`,
      borderRight: `8px solid ${secondaryColour}`,
    };
    return (
      <tr>
        <td style={{ padding: "0 0 0 0px" }} align="left">
          <table cellSpacing="0" cellPadding="0">
            <tbody>
              <tr>
                <td align="center">
                  <table width="100%" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <td align="center" style={{ padding: 0, fontFamily: fontFamilySecondary }}>
                          <table cellSpacing="0" cellPadding="0">
                            <tbody>
                              <tr>
                                <td align="center">
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      lineHeight: "35px",
                                      fontWeight: "bold",
                                      color: "#ffffff",
                                      textDecoration: "none",
                                      backgroundColor: secondaryColour,
                                      fontFamily: fontFamilySecondary,
                                      display: "inline-block,",
                                      ...border,
                                    }}
                                  >
                                    {name}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  };

  const renderJob = () => (
    <tr>
      <td
        align="left"
        style={{
          padding: "4px 0px 0px 0px",
          fontSize: "16px",
          lineHeight: "16px",
          fontFamily: fontFamily, 
          color: secondaryColour,
        }}
      >
        <strong>{job}</strong> @BossaGames
      </td>
    </tr>
  );

  const renderPronouns = () => (
    <tr>
      <td
        align="left"
        style={{
          color: secondaryColour,
          padding: `${defaultCellTopSpace} 0px 0px 0px`,
          fontSize: "12px",
          lineHeight: "12px",
          fontFamily: fontFamily,
        }}
      >
        {pronouns}
      </td>
    </tr>
  );


  const renderWebsite = () => (
    <tr>
      <td
        align="left"
        style={{
          padding: `${defaultCellTopSpace} 0px 0px 0px`,
          fontFamily: fontFamily,
          fontSize: "14px",
          lineHeight: "14px",
          fontWeight: "bold",
          color: secondaryColour,
        }}
      >
        <a
          href={website}
          style={{
            fontFamily: fontFamily,
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "bold",
            color: secondaryColour,
            textDecoration: "underline",
          }}
        >
          {websiteShort}
        </a>
      </td>
    </tr>
  );

  return (
    <>
      <table cellPadding="0" cellSpacing="0" width="100%" style={{ tableLayout: "fixed" }}>
        <tbody>
          <tr>
            <td align="left" style={{ padding: "12px 4px 12px 4px" }}>
              <table cellPadding="0" cellSpacing="0" width="100%" style={{ background: "#ffffff" }}>
                <tbody>
                  <tr>
                    <td valign="top" style={{ padding: "12px 0 4px 0" }}>
                      {renderLogo()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table cellSpacing="0" cellPadding="0" width="100%">
                        <tbody>
                          {name && renderName()}
                          {job && renderJob()}
                          {pronouns && renderPronouns()}
                          {renderWebsite()}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default EmailTable;
