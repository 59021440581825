import cx from "classnames";
import { useState } from "react";

import howTo from "../../images/howto.gif";
import styles from "./styles.module.scss";

const Instructions = () => {
  const [open, setOpen] = useState<boolean>(false);
  const openAdvanced = () => {
    setOpen(!open);
  };

  return (
    <div className={cx("constrain", styles.Instructions)}>
      <h2>Instructions:</h2>
      <img src={howTo} alt="Fill in your details. Select the preview (click and drag). Right click and copy. Paste signature in your email client." />
      <ol>
        <li>Fill in your details.</li>
        <li>Select the preview (click and drag).</li>
        <li>Right click and copy.</li>
        <li>Paste signature in your email client.</li>
      </ol>

      <h3 className={styles.howToLink} onClick={openAdvanced}>Click here if you're a developer</h3>
      <div className={styles.advanced} style={{display: open ? "block" : "none"}}>
        <h3>Updating images</h3>
        <div>
          <p>The most important step here is to NEVER delete the old assets (unless they are in exactly the same dimensions and you don't mind any already sent emails to be updated as well).</p>

          <h4>Requirements for the assets:</h4>

          <div className={styles.note}>Make sure the image is scaled down and optimised for web to keep the file size down</div>

          <h4>Replace the image like-for-like (updates past and future email signatures)</h4>
          <p>Useful when the new image has only small tweaks or exactly the same size</p>
          <ol>
            <li>It's very important that the image is exactly the same width and height &amp; has the same name as the image you're replacing</li>
            <li>Go to AWS Management Console. You may use any way you prefer to access it. Here's how to in browser:</li>
              <ol>
                <li>Once logged in, find All Services &gt; Storage &gt; S3</li>
                <li>Download the existing image first so that you can restore it if needed</li>
                <li>Navigate to the Bossa s3 Signature bucket and upload your asset there - override it if asked to</li>
              </ol>
          </ol>
          <h4>Upload a new image (with a new name) </h4>
          <ol>
            <li>You may use different image dimensions to the currently used image, but try and keep it similar width</li>
            <li>Go to AWS Management Console. You may use any way you prefer to access it. Here's how to in browser:</li>
              <ol>
                <li>Once logged in, find All Services &gt; Storage &gt; S3</li>
                <li>Navigate to the Bossa s3 Signature bucket and upload your asset there - do not override the image, it has to have a new name</li>
              </ol>
          </ol>
          <h4>Further steps:</h4>
            <ol>
            <li>In AWS</li>
              <ol>
                <li>Leave bucket versioning option ticked off - this means you won't need to change the URL to the image if you wanted to replace it later on</li>
                <li>Make sure you set the right permissions for it (it needs to be set to publicly available or you won't be able to view the image in the tool or email signatures)</li>
                <li>Copy the Object URL - this is your new URL to update in the code</li>
              </ol>
            <li>In code:</li>
              <ol>
                <li>Locate data.ts file and update the URL in defaults &gt; logo</li>
              </ol>
          </ol>

          <div className={styles.note}>
            <strong>Note:</strong>
            <p><strong>Replace the image like-for-like</strong> can be handy because it doesn't require the users to update their signature. Because the URL and size of the image is the same, this is all you need to do and old as well as new signatures will update automatically.</p>
            <p><strong>Upload a new image (with a new name)</strong> is useful for times where your branding changes for example. It means that you can only update any new signatures without affecting any emails your employees already sent out. It does require everyone to use the tool again and set their new signatures in order to show up.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
