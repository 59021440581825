interface IInput extends React.HTMLProps<HTMLInputElement> {}

const Input = ({ id, label, required, placeholder, type, value, onChange, ...props }: IInput) => (
  <>
    <label htmlFor={id}>{label}</label>
    <input
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete="off"
      autoCapitalize="off"
      {...required}
      {...props}
    />
  </>
);

export default Input;
