import { useState, FormEvent } from "react";

import cx from "classnames";

import Input from "../Input";
import Select from "../Select";
import EmailTable from "../EmailTable";

import { brands, IBrand, IHTMLInput } from "../../data/data";

import styles from "./styles.module.scss";

const Details = () => {
  const [brand, setBrand] = useState<IBrand>(brands[0]);
  const [name, setName] = useState<IHTMLInput>("");
  const [pronouns, setPronouns] = useState<IHTMLInput>("");
  const [job, setJob] = useState<IHTMLInput>("");

  const selectBrand = (e: FormEvent<HTMLSelectElement>) => {
    brands.find((b) => b.value === e.currentTarget.value);
    setBrand(brand);
  };

  return (
    <div className={cx(styles.Details, "constrain")}>
      <h1>Create your email signature</h1>
      <form>
        {brands.length > 1 && (
          <Select
            options={brands}
            id="brand"
            label="Select your brand"
            value={brand.value}
            onChange={selectBrand}
          ></Select>
        )}
        <fieldset>
        <Input
          label="Your name"
          type="text"
          id="name"
          placeholder="Your name"
          value={name}
          onChange={(e: FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
        />
        </fieldset>
        <fieldset>
        <Input
          label="Pronouns (optional)"
          type="text"
          id="name"
          placeholder="Pronouns"
          value={pronouns}
          onChange={(e: FormEvent<HTMLInputElement>) => setPronouns(e.currentTarget.value)}
        />
        </fieldset>
        <fieldset>
        <Input
          label="Job title"
          type="text"
          id="job"
          placeholder="Web Developer"
          value={job}
          onChange={(e: FormEvent<HTMLInputElement>) => setJob(e.currentTarget.value)}
        /></fieldset>
      </form>

      <div className={styles.preview}>
        <h3>Desktop preview</h3>
        <div className={styles.previewBox}>
          <EmailTable
            brand={brand}
            name={name as string}
            pronouns={pronouns as string}
            job={job as string}
          ></EmailTable>
        </div>

        <h3>Mobile preview</h3>
        <div className={cx(styles.previewBox, styles.previewBoxMobile)}>
          <EmailTable
            brand={brand}
            name={name as string}
            pronouns={pronouns as string}
            job={job as string}
          ></EmailTable>
        </div>
      </div>
    </div>
  );
};

export default Details;
