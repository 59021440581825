import React, { FC } from "react";
import classnames from "classnames";

import css from "./styles.module.css";
import { ReactComponent as logo } from "./svgs/bossaGamesLogo.svg";

export const iconMap = {
  logo,
};

export type IconType = keyof typeof iconMap;

export interface IIconProps extends React.HTMLProps<HTMLSpanElement> {
  icon: IconType;
  className?: string;
}

export const Icon: FC<IIconProps> = ({ className, icon, ...props }) => {
  const Svg = iconMap[icon];
  const classNames = classnames(css.icon, css[icon], className);

  return (
    <span className={classNames} {...props}>
      <Svg className={css.svg} />
    </span>
  );
};

export default Icon;
