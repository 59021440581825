// import styles from './styles.module.scss';

interface IOption {
  value: string;
  name: string;
}

interface ISelect {
  id: string;
  value: string;
  label: string;
  options: IOption[];
  required?: boolean;
  onChange?: (e: React.FormEvent<HTMLSelectElement>) => void;
}

const Select = ({ id, value, label, required, options, onChange }: ISelect) => {
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <select {...required} id={id} onChange={onChange} value={value}>
        {options.map((opt) => (
          <option value={opt.value}>{opt.name}</option>
        ))}
      </select>
    </>
  );
};

export default Select;
