import Details from "./components/Details";
import Instructions from "./components/Instructions";
import Icon from "./components/Icon";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Icon className="App-logo" icon="logo" />
      </header>
      <Details></Details>
      <Instructions></Instructions>
    </div>
  );
}

export default App;
